import { SearchIcon } from 'components/Icons/SearchIcon'
import {
  ChangeEvent,
  FC,
  FocusEvent,
  FormEvent,
  FormHTMLAttributes,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import './Search.css'
import { StyledBadgeButton, StyledBadgesWrapper } from './Search.style'

interface Badge {
  label: string
  selected?: boolean | undefined
}

export interface SearchFormProps extends FormHTMLAttributes<HTMLFormElement> {
  badges?: undefined | Array<Badge>
  suggestions?: Array<Suggestion> | undefined
  onSearch?: ((value: string) => void) | undefined
  onSuggest?: ((value: string) => void) | undefined
  onBadgeSelect?: ((badge: Badge) => void) | undefined
  onReset?: () => void
  defaultValue?: string
  throttle?: number | undefined
  placeholder?: string
}

type Suggestion = {
  value: string
  userInput: string
  suffix: string
}

export const SearchForm: FC<SearchFormProps> = (props) => {
  const {
    badges,
    className,
    placeholder,
    throttle = 500,
    suggestions,
    defaultValue = '',
    onBadgeSelect,
    onSuggest,
    onSearch,
    onReset,
    ...otherProps
  } = props

  const [inputValue, setInputValue] = useState('')
  const [visibleSuggestions, setVisibleSuggestions] = useState(false)
  const [_placeHolder, _setPlaceHolder] = useState(placeholder)
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const suggestionsTimeout = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    if (
      inputValue.trim().length === 0 ||
      (suggestions && inputValue === suggestions[0]?.value)
    ) {
      setVisibleSuggestions(false)
      onReset && onReset()
      return
    }
    if (suggestionsTimeout.current) clearTimeout(suggestionsTimeout.current)
    suggestionsTimeout.current = setTimeout(() => {
      if (onSuggest) onSuggest(inputValue)
    }, throttle)
    return () => {
      if (suggestionsTimeout.current) clearTimeout(suggestionsTimeout.current)
    }
  }, [inputValue])

  useEffect(() => {
    if (
      inputValue.trim() !== '' &&
      suggestions &&
      suggestions.length > 0 &&
      inputValue !== suggestions[0]?.value
    ) {
      setVisibleSuggestions(true)
    } else setVisibleSuggestions(false)
  }, [suggestions])

  const onInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value)
    if (event.currentTarget.value.length === 0) {
      setSearchButtonDisabled(true)
    } else {
      setSearchButtonDisabled(false)
    }
  }

  const onSuggestionClickHandler = (
    event: MouseEvent<HTMLButtonElement>,
    value: string
  ) => {
    setInputValue(value)
  }

  const onInputFocusHandler = (event: FocusEvent<HTMLInputElement>) => {
    _setPlaceHolder('')
    if (!event.currentTarget.value.length) return
    setSearchButtonDisabled(false)
  }

  const onInputBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
    _setPlaceHolder(placeholder)
    if (event.relatedTarget === buttonRef.current) return
    setSearchButtonDisabled(true)
  }

  const onFormSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setVisibleSuggestions(false)
    setSearchButtonDisabled(true)
    if (onSearch) onSearch(inputValue)
  }

  const onBadgeClickHandler = (
    event: MouseEvent<HTMLButtonElement>,
    badge: Badge
  ) => {
    if (onBadgeSelect) onBadgeSelect(badge)
  }

  return (
    <form
      className={classNames('search-form-f98b', className)}
      onSubmit={onFormSubmitHandler}
      {...otherProps}
    >
      <div className={classNames('text-input-container-f98b', className)}>
        <input
          className="input-f98b"
          value={inputValue || defaultValue}
          placeholder={_placeHolder}
          onChange={onInputChangeHandler}
          onFocus={onInputFocusHandler}
          onBlur={onInputBlurHandler}
          type="text"
        />
        <button
          className="search-button-f98b"
          {...(searchButtonDisabled ? { disabled: true } : {})}
          ref={buttonRef}
          type="submit"
        >
          <SearchIcon className="std-icon icon-f98b" />
        </button>
      </div>

      {visibleSuggestions && suggestions && (
        <div className="search-suggestions-f98b">
          <ul className="search-suggestions-ul-f98b">
            {suggestions.map((suggestion, index) => (
              <li className="search-suggestions-li-f98b" key={index}>
                <button
                  className="suggestion-button-f98b"
                  type="submit"
                  onClick={(event) =>
                    onSuggestionClickHandler(event, suggestion.value)
                  }
                  tabIndex={-1}
                >
                  <b>{suggestion.userInput}</b>
                  <span>{suggestion.suffix}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {badges && badges.length > 0 && (
        <StyledBadgesWrapper>
          {badges?.map((badge, index) => (
            <StyledBadgeButton
              key={index}
              className={classNames({
                'selected-f98b': badge.selected,
              })}
              onClick={(event) => onBadgeClickHandler(event, badge)}
              type="button"
            >
              {badge.label}
            </StyledBadgeButton>
          ))}
        </StyledBadgesWrapper>
      )}
    </form>
  )
}
