import { AtomButton } from 'atoms/AtomButton'
import { BootstrapCols } from 'commons/BootstrapCols'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import { Colors } from 'commons/Theme'
import {
  consoleLog,
  eventDataLayer,
  getGA4PageSezione,
  prepareStringForDataLayer,
  removeStyleFromHtmlString,
  removeTrailingSlashes,
} from 'commons/utils'
import { Html } from 'components/App/Html'
import DynamicComponent, {
  ComponentDataModel,
} from 'components/DynamicComponent/DynamicComponent'
import {
  ParagraphAccordionFeData,
  isParagraphAccordionFe,
} from 'components/DynamicComponent/map/ParagraphAccordionFe'
import { Icon } from 'components/Icons/Icon'
import MarkupFaqHead from 'components/MarkupFaqHead/MarkupFaqHead'
import { Accordion, AccordionTheme } from 'components/UI/Accordion'
import { CaretRightIcon } from 'components/Icons/CaretRightIcon'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import { graphql } from 'gatsby'
import {
  StyledFAQCategoria,
  StyledFAQItem,
  StyledFAQLabel,
  StyledNoResults,
  StyledResultsTitle,
  StyledShowMoreWrapper,
} from 'pages-style/domande-frequenti'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../Layout'
import { ContainerFluid } from '../commons/Container'
import Margini from '../components/Margini/Margini'
import Section from '../components/Section/Section'
import { AccordionItem } from '../components/UI/Accordion'
import { Separator } from '../components/UI/Separator'
import { StyledResultsWrapper } from '../pages-style/domande-frequenti'
import { useFeedbackQA } from '../hooks/use-feedback-qa'
import { PageDataContext } from 'contexts/PageDataContext'
import { PageDataBaseType } from 'models/page-model'
import {
  ParagraphSezioneFeData,
  isParagraphSezioneFe,
} from 'components/DynamicComponent/map/ParagraphSezioneFe'
import { ParagraphHeroWrapperFeData } from 'components/DynamicComponent/map/ParagraphHeroWrapperFe'
import classNames from 'classnames'
import { HeroImage } from 'components/HeroImage/HeroImage'
import { SearchForm } from 'components/Search/Search'

function getFAQs(page: PageProps) {
  const sezioneFe = page.relationships.field_struttura_content.find(
    (el) =>
      isParagraphSezioneFe(el) &&
      el.relationships?.field_componente_sezione &&
      el.relationships?.field_componente_sezione?.length > 0 &&
      el.relationships?.field_componente_sezione?.filter(
        (component) => component.__typename === 'paragraph__accordion_fe'
      )?.length > 0
  )

  let accordion: ParagraphAccordionFeData | undefined = undefined
  if (sezioneFe && isParagraphSezioneFe(sezioneFe)) {
    const findAccordion =
      sezioneFe.relationships?.field_componente_sezione?.find(
        (component) => component.__typename === 'paragraph__accordion_fe'
      )

    accordion =
      findAccordion && isParagraphAccordionFe(findAccordion)
        ? findAccordion
        : undefined
  }

  if (!accordion) {
    return null
  }

  return (
    accordion.relationships?.field_accordion_item?.map((faq) => ({
      title: faq.field_accordion_titolo,
      content: faq.field_accordion_descrizione?.processed ?? '',
    })) || null
  )
}

interface FAQItemProps {
  categoria?: string
  label: string
  link: string
  onClick: () => void
}

const FAQItem = ({ categoria, label, link, onClick }: FAQItemProps) => {
  return (
    <StyledFAQItem to={link} onClick={onClick}>
      <div>
        {categoria && <StyledFAQCategoria>{categoria}</StyledFAQCategoria>}
        <StyledFAQLabel>{label}</StyledFAQLabel>
      </div>
      <Icon width="24px" stroke={Colors.black} Icon={CaretRightIcon} />
    </StyledFAQItem>
  )
}

const pageSize = 10

export default function PageDefaultDomandeFrequenti(props: PageDataProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchUrlTerm, setSearchUrlTerm] = useState('')
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([])
  const [allResults, setAllResults] = useState<ResultType[]>([])
  const [filteredResults, setFilteredResults] = useState<ResultType[]>([])
  const [activeResults, setActiveResults] = useState<ResultType[]>([])
  const [badges, setBadges] = useState<BadgeType[]>([])
  const [noResults, setNoResults] = useState<boolean>(false)
  const [top10, setTop10] = useState<top10Type[]>()
  const { feedbackAnswers, feedbackQuestion } = useFeedbackQA()

  const {
    data: { page },
    pageContext: { title },
  } = props

  const [heroData, ...contentData] = page.relationships.field_struttura_content

  const bodyContent = (heroData as ParagraphHeroWrapperFeData)?.relationships
    ?.field_hero[0]?.field_hero_testo?.processed

  function fetchSuggestions(inputValue: string) {
    if (inputValue.length > 2) {
      fetch(
        `${removeTrailingSlashes(
          process.env.A2A_DRUPAL_CASA_URL
        )}/a2a_search_suggester/ricerca_solr_domande_frequenti?q=${encodeURIComponent(inputValue)}`
      )
        .then((res) => res.json())
        .then((res) => {
          setSuggestions(res)
          setSearchTerm(inputValue)
        })
        .catch((err) => consoleLog(err))
    } else {
      setSuggestions([])
    }
  }

  function fetchResults(inputValue: string) {
    fetch(
      `${removeTrailingSlashes(
        process.env.A2A_DRUPAL_CASA_URL
      )}/rest/ricerca/domande_frequenti?keys=${inputValue}`
    )
      .then((res) => res.json())
      .then((res: ResultType[]) => {
        return res.map((result) => {
          result.url = result.url.replace('/casa/', '/')
          return result
        })
      })
      .then((res: ResultType[]) => {
        setAllResults(res)
        setFilteredResults(res)
        setActiveResults(res.slice(0, pageSize))
        setSearchTerm(inputValue)
        setSuggestions([])
        if (res.length === 0) {
          setNoResults(true)
          eventDataLayer({
            event: 'ricerca_interna_faq',
            stream: 'web',
            search_term: prepareStringForDataLayer(inputValue),
            risultato_ricerca: 'no',
            sezione: getGA4PageSezione(),
            azione: 'risultati_ricerca',
          })
        } else {
          eventDataLayer({
            event: 'ricerca_interna_faq',
            stream: 'web',
            search_term: prepareStringForDataLayer(inputValue),
            risultato_ricerca: 'si',
            sezione: getGA4PageSezione(),
            azione: 'risultati_ricerca',
          })
          const uniqueBadges = [
            ...new Set(res.map((res) => res.parent ?? '').filter((res) => res)),
          ]
          setBadges([
            { label: 'Tutti', selected: true },
            ...uniqueBadges.map((it) => ({ label: it, selected: false })),
          ])
          setNoResults(false)
        }
      })
      .catch((err) => consoleLog(err))
  }

  function handleOnBadgeClick(badge: BadgeType) {
    setFilteredResults(
      badge.label === 'Tutti'
        ? [...allResults]
        : allResults.filter((x) => x.parent === badge.label)
    )
    setBadges((prev) =>
      prev.map((it) => ({
        label: it.label,
        selected: it.label === badge.label,
      }))
    )
  }

  function handleOnReset() {
    setAllResults([])
    setFilteredResults([])
    setActiveResults([])
    setSearchUrlTerm('')
    setNoResults(false)
    setBadges([])
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const query = params.get('keys')
    if (query) {
      setSearchUrlTerm(query)
      fetchResults(query)
    }

    fetch(
      `${removeTrailingSlashes(
        process.env.A2A_DRUPAL_URL
      )}/a2a-api/top-list?_format=json`
    )
      .then((res) => res.json())
      .then((res) => setTop10(res || []))
      .catch(() =>
        console.warn('Error while fetching from /a2a-api/top-list?_format=json')
      )
  }, [])

  useEffect(() => {
    setActiveResults([...filteredResults].slice(0, pageSize))
  }, [filteredResults])

  return (
    <A2ADataContextProvider>
      <Layout pageTitle={page?.field_meta_tags_fe?.title || title}>
        <Helmet
          bodyAttributes={{
            class: classNames(
              'a2a-template-domande-frequenti',
              'a2a-navigation-main',
              'a2a-navigation-full'
            ),
          }}
        >
          {page?.field_meta_tags_fe?.description && (
            <meta
              name="description"
              content={page?.field_meta_tags_fe?.description}
            />
          )}
        </Helmet>

        <MarkupFaqHead items={getFAQs(page) || []} />

        <CanonicalUrlComponent url={page?.path?.alias} />

        <PageDataContext.Provider
          value={{
            data: page,
          }}
        >
          <HeroImage
            backgroundColor="blue"
            rawContent={
              bodyContent ? removeStyleFromHtmlString(bodyContent) : undefined
            }
            breadcrumbLinks={
              page.relationships?.field_breadcrumb_content
                ?.field_link_breadcrumb
                ? [
                    ...page.relationships.field_breadcrumb_content
                      .field_link_breadcrumb,
                    { title: page.title, uri: page.path.alias },
                  ]
                : undefined
            }
          >
            <SearchForm
              className="search-8d64"
              defaultValue={searchUrlTerm}
              badges={badges}
              suggestions={suggestions}
              placeholder="Digita la tua parola chiave"
              onSuggest={fetchSuggestions}
              onSearch={fetchResults}
              onBadgeSelect={handleOnBadgeClick}
              onReset={handleOnReset}
            />
          </HeroImage>
          {!filteredResults.length && !noResults && contentData && (
            <>
              <Margini spacing="XLarge" />
              {top10 && (
                <Section titolo="Argomenti di tendenza" titoloRientrato>
                  <Accordion>
                    {top10.map((faq) => (
                      <AccordionItem
                        key={faq.uuid}
                        className={AccordionTheme.Flat}
                        head={faq.title}
                        faqID={faq.uuid}
                        hasFeedback={faq.feedbackEnabled}
                        feedbackQuestion={feedbackQuestion}
                        feedbackAnswers={feedbackAnswers}
                      >
                        <Html>{faq.body}</Html>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </Section>
              )}
              <Margini spacing="XLarge" />
              <ContainerFluid>
                <Separator />
              </ContainerFluid>
              {contentData.map((field, i) => (
                <DynamicComponent
                  key={i}
                  component={field.__typename}
                  data={field}
                  related={{
                    parent: undefined,
                    siblings: {
                      before:
                        page.relationships.field_struttura_content[i - 1] ??
                        undefined,
                      after:
                        page.relationships.field_struttura_content[i + 1] ??
                        undefined,
                    },
                  }}
                />
              ))}
            </>
          )}
          {(!!activeResults.length || noResults) && (
            <StyledResultsWrapper>
              <BootstrapCols cols={12} desktopCols={10}>
                <StyledResultsTitle noResults={noResults}>{`${
                  allResults.length
                } ${
                  allResults.length === 1 ? 'Risultato' : 'Risultati'
                } per ${searchTerm}`}</StyledResultsTitle>
                {noResults && (
                  <StyledNoResults>
                    Verifica di aver scritto correttamente il testo oppure
                    effettua un&lsquo;altra ricerca.
                  </StyledNoResults>
                )}

                {activeResults.map((result, i) => (
                  <FAQItem
                    key={i}
                    categoria={result.parent}
                    label={result.name}
                    link={result.url}
                    onClick={() =>
                      eventDataLayer({
                        event: 'ricerca_interna_faq',
                        stream: 'web',
                        search_term: prepareStringForDataLayer(searchTerm),
                        risultato_selezionato: prepareStringForDataLayer(
                          result.name
                        ),
                        sezione: getGA4PageSezione(),
                        azione: 'click_risultati_ricerca',
                      })
                    }
                  />
                ))}
                {activeResults.length < filteredResults.length && (
                  <StyledShowMoreWrapper>
                    <AtomButton
                      label="Mostra altre domande frequenti"
                      styleType="ghost"
                      onClick={() =>
                        setActiveResults((prev) =>
                          filteredResults.slice(0, prev.length + pageSize)
                        )
                      }
                    />
                  </StyledShowMoreWrapper>
                )}
              </BootstrapCols>
            </StyledResultsWrapper>
          )}
        </PageDataContext.Provider>
      </Layout>
    </A2ADataContextProvider>
  )
}

type ResultType = {
  name: string
  url: string
  field_domande_sottotitolo: string
  field_hub_emoving?: boolean | null
  tid: string
  parent?: string
  parent_tid?: string
}

type SuggestionType = {
  suffix: string
  userInput: string
  value: string
}

type BadgeType = {
  label: string
  selected?: boolean
}

interface PageProps extends PageDataBaseType {
  id: string
  drupal_id: string
  internal: {
    type: 'a2a_newsite__content_new_site'
  }
  title: string
  status: boolean
  field_is_homepage: boolean
  path: {
    alias: string
  }
  field_meta_tags_fe: {
    title: string
    description: string
  }
  relationships: {
    field_breadcrumb_content?: {
      __typename: string
      id: string
      drupal_id: string
      internal: {
        type: string
      }
      parent_type: string
      parent_field_name: string
      field_link_breadcrumb:
        | {
            title: string
            uri: string
          }[]
        | null
    } | null
    field_struttura_content: (
      | ParagraphSezioneFeData
      | ParagraphAccordionFeData
      | ComponentDataModel
    )[]
  }
}

type top10Type = {
  title: string
  body: string
  uuid: string
  feedbackEnabled: boolean
}

interface PageDataProps {
  data: {
    page: PageProps
  }
  pageContext: {
    pageId: string
    title: string
    drupal_id: string
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: a2ANewsiteContentNewSite(id: { eq: $id }) {
      # See src/templates/default.tsx
      ...A2AContentNewSiteFragment
    }
  }
`
