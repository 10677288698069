import { LinkProps } from './Link.types'

const Link = (props: LinkProps) => {
  const { to, className, title, target, rel, children, onClick, ...rest } =
    props

  return to ? (
    <a
      href={to}
      className={className}
      title={title}
      target={to.indexOf('http') === 0 ? target : '_self'}
      rel={rel}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }
      }}
      {...rest}
    >
      {children}
    </a>
  ) : null
}

export default Link
