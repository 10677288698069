import { FC } from 'react'
import { Helmet } from 'react-helmet'
import {
  MarkupFaqHeadJsonLdType,
  MarkupFaqHeadProps,
} from './MarkupFaqHead.types'

const MarkupFaqHead: FC<MarkupFaqHeadProps> = ({ items }) => {
  const faqJsonHead: MarkupFaqHeadJsonLdType = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map((item) => {
      return {
        '@type': 'Question',
        name: item.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.content,
        },
      }
    }),
  }

  if (items.length === 0) {
    return null
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(faqJsonHead)}</script>
    </Helmet>
  )
}

export default MarkupFaqHead
