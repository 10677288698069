import { PageDataBaseType } from 'models/page-model'
import { createContext } from 'react'

interface PageDataContextType {
  data: PageDataBaseType
}

export const PageDataContext = createContext<PageDataContextType>(
  {} as PageDataContextType
)
