import { graphql, useStaticQuery } from 'gatsby'

export const useFeedbackQA = (): FeedbackQAType => {
  const feedbackQA = useStaticQuery<FeedbackQAJSONType>(graphql`
    query FeedbackQA {
      allTaxonomyTermDomandeFeedbackFaq {
        nodes {
          name
          drupal_id
        }
      }
      allTaxonomyTermRisposteFeedbackFaq {
        edges {
          node {
            name
            drupal_id
          }
        }
      }
    }
  `)
  return {
    feedbackQuestion: feedbackQA.allTaxonomyTermDomandeFeedbackFaq.nodes,
    feedbackAnswers: feedbackQA.allTaxonomyTermRisposteFeedbackFaq.edges.map(
      (node) => node.node
    ),
  }
}

type FeedbackQAJSONType = {
  allTaxonomyTermDomandeFeedbackFaq: {
    nodes: FeedbackQANode[]
  }
  allTaxonomyTermRisposteFeedbackFaq: {
    edges: AnswerJSONType[]
  }
}

type AnswerJSONType = {
  node: FeedbackQANode
}

export type FeedbackQANode = {
  drupal_id: string
  name: string
}
type FeedbackQAType = {
  feedbackQuestion: FeedbackQANode[]
  feedbackAnswers: FeedbackQANode[]
}
