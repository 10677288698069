import { Palette, SiteSpacings, Typography } from 'commons/Theme'
import styled from 'styled-components'

export const StyledBadgesWrapper = styled.div`
  display: flex;
  gap: ${SiteSpacings.Spacing04.sm}px;
  flex-wrap: wrap;
  margin-top: ${SiteSpacings.Spacing05.sm}px;
`

export const StyledBadgeButton = styled.button`
  ${Typography.BodyRegular.sm};
  background: ${Palette.Azure[50]};
  color: ${Palette.Blue[800]};
  border: none;
  border-radius: 999px;
  padding: ${SiteSpacings.Spacing03.sm}px ${SiteSpacings.Spacing04.sm}px;
  transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);

  &:hover {
    color: ${Palette.Azure[600]};
  }

  &.selected-f98b {
    background: ${Palette.Blue[800]};
    color: #ffffff;
  }
`
