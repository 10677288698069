import { ContainerFluid } from 'commons/Container'
import { BPSizes, Colors, StyledH3 } from 'commons/Theme'
import Link from 'components/Link/Link.export'
import styled from 'styled-components'

export const StyledFAQItem = styled(Link)`
  margin-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.grey200};
  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin-top: 24px;
    padding-bottom: 24px;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    margin-top: 32px;
    padding-bottom: 32px;
  }
`

export const StyledFAQLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: ${Colors.black};
  @media screen and (min-width: ${BPSizes.tablet}px) {
    font-size: 18px;
    line-height: 1.35;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: 24px;
  }
`

export const StyledFAQCategoria = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${Colors.azure600};
  margin-bottom: 16px;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: 16px;
  }
`

export const StyledResultsTitle = styled(StyledH3)<{ noResults?: boolean }>`
  margin-bottom: ${({ noResults }) => (noResults ? '16px' : '72px')};
`

export const StyledNoResults = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.35;
  color: ${Colors.grey700};
`

export const StyledResultsWrapper = styled(ContainerFluid)`
  margin-top: 40px;
  margin-bottom: 80px;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin-top: 80px;
    margin-bottom: 120px;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    margin-top: 120px;
    margin-bottom: 160px;
  }
`

export const StyledShowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 72px;
`
